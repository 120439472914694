import { useModalContext } from 'src/design-system'
import { store } from 'store/index'

export const NEW_TEAM_MODAL_ID = 'NEW_TEAM_MODAL'

export const EVENT_KEY_NAME = '$track_get_started_modal_click'

export const useOpenNewTeamModal = () => {
  const { openModal } = useModalContext()

  return {
    openNewTeamModal: (templateId?: string) => {
      const currentUser = store.currentUser
      if (!currentUser) return

      if (currentUser.org?.activeSubscription) {
        openModal(NEW_TEAM_MODAL_ID, { templateId })
      } else {
        window.location.href = currentUser.isAdmin
          ? `/orgs/${currentUser.org?.slug}/billing/new`
          : '/'
      }
    },
  }
}
